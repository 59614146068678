import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useState
} from "react";
import { Badge, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { deleteTask, editTask, getTasks } from "../../services/task";
import { updateNotification } from "../../store/reducers/notificationSlice";
import { isTitle } from "../../helpers/titles";
import { NotificationManager } from "react-notifications";
import { getMembers } from "../../services/member";

const Tasks = ({ userId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const inputRef = createRef();
  const role = useSelector((state) => state.auth.user.role);
  const taskNotification = useSelector((state) => state.notification.task);

  const [tasks, setTasks] = useState({
    loading: true,
    items: [],
  });
  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });
  const [members, setMembers] = useState({
    loading: true,
    items: [],
  });


  const onEditTask = useCallback((data) => {
    if (!data.newMemberId) {
      return NotificationManager.error(
        "Выберите сотрудника"
      );
    }
    if (!data.status == "close") {
      return NotificationManager.error(
        "Задача закрыта"
      );
    }
    editTask(data)
      .then((res) => {
        if (!res?.memberId) {
          NotificationManager.success("Сотрудник задачи назначен");
        } else {
          NotificationManager.success("Сотрудник задачи изменен");
        }
        getData();
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      );
  }, []);

  const taskColumns = [
    {
      width: "130px",
      name: "Тип",
      align: "center",
      cell: (row) => (
        <Link to={`/task/${row?.id}`}>
          {row.type === "task" ? (
            <Badge bg="success">Обращение</Badge>
          ) : row.type === "word" ? (
            <Badge bg="warning">Стоп-слово</Badge>
          ) : (
            row.type === "report" && <Badge bg="danger">Жалоба</Badge>
          )}
        </Link>
      ),
    },
    {
      name: "Дата",
      selector: "createdAt",
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="fw-7">{moment(row.createdAt).format("kk:mm")}</span>
          <span >
            {moment(row.createdAt).format("DD.MM.YYYY")}
          </span>

        </div>
      ),
    },

    {
      width: "130px",
      name: "Статус",
      align: "center",
      cell: (row) =>
        !row.status || row.status === "new" ? (
          <Badge bg="success">Новый</Badge>
        ) : row.status === "process" ? (
          <Badge bg="secondary">В процессе</Badge>
        ) : row.status === "ok" ? (
          <Badge bg="success">Выполнено</Badge>
        ) : (
          row.status === "close" && <Badge bg="danger">Закрыто</Badge>
        ),
    },
    {
      name: "Пользователь",
      cell: (row) => {
        if (row?.author?.nickname) {
          return (
            <div className="d-flex flex-column">
              <Link to={`/user/${row.author.id}`}>{row.author.nickname}</Link>
              {row?.user?.nickname && (
                <Link to={`/user/${row.user.id}`}> =&gt; {row.user.nickname}</Link>
              )}
            </div>
          );
        } else {
          return "-";
        }
      },
    },


    {
      align: "center",
      name: "Категория",
      cell: (row) => <Link to={`/task/${row?.id}`}>{isTitle(row?.title)}</Link>,
    },
    {
      align: "center",
      name: "Тема",
      cell: (row) => <Link to={`/task/${row?.id}`}>{isTitle(row?.title, row?.subTitle)}</Link>,
    },

    {
      align: "right",
      name: role == 1 ? "Исполнители" : "",
      cell: (row) => {
        const [member, setMember] = useState({ id: null, memberId: null });
        const varMembers = members.items.filter(e => e?.taskTitle?.some(title => title.includes(String(row?.title))));
        return (
          <div className="d-flex align-items-center justify-content-between">
            {role == 1 ?
              <>
                <Select
                  className="select-sm"
                  onClick={(e) => {
                    setMember({ id: row.id, memberId: e.value });
                  }}
                  value={member.memberId ?? row.memberId ?? ""}
                  data={[{ title: "Никого", value: "all" },
                  ...varMembers.map((e) => ({
                    title: e.firstName,
                    value: e.id,
                  }))]}
                />
                <Button
                  className="btn-primary ms-2 me-2 btn-sm"
                  disabled={!member.memberId || row.status == "close"}
                  onClick={() => {
                    onEditTask({ ...row, newMemberId: member.memberId });
                  }}
                >
                  Назначить
                </Button>
              </>
              :
              row.memberId ?
                <span>{row.member.firstName}</span>
                :
                <Button
                  className="btn-primary ms-2 me-2 btn-sm"
                  onClick={() => {
                    onEditTask({ ...row });
                  }}
                >
                  Взять
                </Button>
            }
          </div>
        );
      },
    },

    // {
    //   width: "80px",
    //   selector: "action",
    //   align: "right",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="d-flex align-items-center">
    //           <Link to={"/task/" + row.id} className="me-3">
    //             <IoCreateOutline size={22} />
    //           </Link>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const getData = useCallback(async () => {
    dispatch(updateNotification({ task: -1 }));
    if (role == 1) {
      getMembers()
        .then((res) => {
          setMembers((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }));
          socket.emit("member/list");
        })
        .catch(() => setMembers((prev) => ({ ...prev, loading: false })));
    }
    getTasks(searchParams)
      .then((res) => {
        setTasks((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
      })
      .finally(() => setTasks((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    if (userId) {
      searchParams.set("userId", userId);
      searchParams.set("sort", "report");
      setSearchParams(searchParams);
    }
    getData();
  }, [searchParams.get("page"), taskNotification]);

  const clickDelete = (id) => {
    deleteTask(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };
  console.log(searchParams.toString())
  if (tasks.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Задачи" />
      <section className="box">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Задачи"}
            </h5>
          </div>

          <Select
            classNameContainer="ms-5 w-100"
            label="Сортировка"
            data={[
              { title: "Все", value: "" },
              { title: "Стоп-слова", value: "word" },
              { title: "Обращения", value: "task" },
              { title: "Жалобы", value: "report" },
            ]}
            value={searchParams.get("sort") ?? ""}
            onClick={(e) => {
              searchParams.set("sort", e.value);
              setSearchParams(searchParams);
              onSearch();
            }}
          />
          {role == 1 &&
            <Select
              classNameContainer="ms-5 w-100"
              label="Исполнитель"
              data={[{ title: "Все", value: "" },
              ...members.items.map((e) => ({
                title: e.firstName,
                value: e.id,
              }))]}
              value={searchParams.get("selectedMember") ?? ""}
              onClick={(e) => {
                searchParams.set("selectedMember", e.value);
                setSearchParams(searchParams);
                onSearch();
              }}
            />
          }
        </div>
        <DataTable
          columns={taskColumns}
          onChange={(items) => setSelected(items)}
          data={tasks.items}
          selectable
          pagination={tasks.pagination}
        />
      </section>
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить задачу?
      </CustomModal>
    </Container>
  );
};

export default Tasks;
