import React, { useState, useCallback, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";
import { FaHeading, FaLink } from "react-icons/fa";

const CustomEditor = ({ content = "", onChange, editorId, height = 500 }) => {
  const [value, setValue] = useState(content);

  const handleChange = useCallback((newValue) => {
    const cleanHtml = DOMPurify.sanitize(newValue, {
      ALLOWED_TAGS: [
        "h4",
        "p",
        "strong",
        "em",
        "ul",
        "ol",
        "li",
        "blockquote",
        "a",
      ],
      ALLOWED_ATTR: ["style", "href"],
    });
    setValue(cleanHtml);
    onChange?.(cleanHtml);
  }, [onChange]);


  const handleLinkClick = useCallback(() => {
    const editor = window.tinymce.get(editorId); // Используем editorId
    if (editor) {
      const selectedText = editor.selection.getContent();
      if (selectedText) {
        const url = prompt("Введите URL:");
        if (url) {
          editor.execCommand("mceInsertLink", false, { href: url });
        }
      }
    }
  }, [editorId]);

  // Автосохранение
  useEffect(() => {
    const interval = setInterval(() => {
      const content = window.tinymce.get(editorId)?.getContent(); // Используем editorId
      if (content) {
        localStorage.setItem("draft", content);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [editorId]);

  return (
    <div className="editor-container">
      <Editor
        apiKey="ваш-api-ключ"
        id={editorId} // Уникальный идентификатор для каждого редактора
        value={value}
        init={{
          height: height,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | bold italic underline | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | link | removeformat | h4button | linkbutton",
          content_style: `
            body { font-family: Arial, sans-serif; }
            h4 { font-size: 1.2rem; font-weight: 600; margin: 20px 0 10px 0; }
            p { text-indent: 2em; margin: 0 0 12px 0; white-space: pre-wrap; }
          `,
          block_formats: "Paragraph=p; Heading 4=h4",
          setup: (editor) => {
            editor.ui.registry.addButton("h4button", {
              icon: "heading",
              tooltip: "Заголовок H4",
              onAction: () => editor.formatter.apply("h4"),
            });

            editor.ui.registry.addButton("linkbutton", {
              icon: "link",
              tooltip: "Добавить ссылку",
              onAction: handleLinkClick,
            });
          },
        }}
        onEditorChange={handleChange}
      />
    </div>
  );
};

export default CustomEditor;