import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getCerts = async (data) => {
  const response = await $authApi.get(apiRoutes.CERTS, {
    params: data,
  });
  return response?.data;
};
const getCert = async (id) => {
  const response = await $authApi.get(apiRoutes.CERT, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editCert = async (data) => {
  const response = await $authApi.postForm(apiRoutes.CERT_EDIT, data);
  return response?.data;
};
const deleteCert = async (id) => {
  const response = await $authApi.delete(apiRoutes.CERTS, {
    data: { id },
  });
  return response?.data;
};
const createCert = async (data) => {
  const response = await $authApi.postForm(apiRoutes.CERTS, data);
  return response?.data;
};
export {
  getCerts,
  getCert,
  editCert,
  deleteCert,
  createCert
};
