const titles = [
  {
    "value": 1,
    "title": "Общие вопросы",
    "items": [
      {
        "value": 1,
        "title": "Информация о компании"
      },
      {
        "value": 2,
        "title": "Условия предоставления цифровых товаров"
      },
      {
        "value": 3,
        "title": "Способы оплаты"
      },
      {
        "value": 4,
        "title": "Политика возврата и обмена цифровых товаров"
      },
      {
        "value": 5,
        "title": "Программа лояльности"
      }
    ]
  },
  {
    "value": 2,
    "title": "Технические вопросы",
    "items": [
      {
        "value": 1,
        "title": "Проблемы с регистрацией аккаунта"
      },
      {
        "value": 2,
        "title": "Вход в личный кабинет"
      },
      {
        "value": 3,
        "title": "Ошибки при оформлении заказа"
      },
      {
        "value": 4,
        "title": "Проблемы с платежами"
      },
      {
        "value": 5,
        "title": "Вопросы по активации и использованию цифровых товаров"
      },
      {
        "value": 6,
        "title": "Проблемы с доступом к контенту (скины, аккаунты и т.д.)"
      }
    ]
  },
  {
    "value": 3,
    "title": "Юридические вопросы",
    "items": [
      {
        "value": 1,
        "title": "Условия пользовательского соглашения"
      },
      {
        "value": 2,
        "title": "Политика конфиденциальности и защита персональных данных"
      },
      {
        "value": 3,
        "title": "Защита прав потребителей"
      },
      {
        "value": 4,
        "title": "Авторские права и интеллектуальная собственность на цифровые товары"
      }
    ]
  },
  {
    "value": 4,
    "title": "Прочие вопросы",
    "items": [
      {
        "value": 1,
        "title": "Предложения и отзывы"
      },
      {
        "value": 2,
        "title": "Партнёрство и сотрудничество"
      },
      {
        "value": 3,
        "title": "Запрос на информацию о товаре"
      },
      {
        "value": 4,
        "title": "Вопросы по рекламе и маркетингу"
      }
    ]
  },
  {
    "value": 5,
    "title": "Жалобы и споры",
    "items": [
      {
        "value": 1,
        "title": "Порядок разрешения споров и конфликтов"
      },
      {
        "value": 2,
        "title": "Жалобы на действия продавцов"
      },
      {
        "value": 3,
        "title": "Жалобы на действия покупателей"
      },
      {
        "value": 4,
        "title": "Жалобы на действия сотрудников платформы"
      }
    ]
  }
];

const isTitle = (categoryValue, itemValue = null) => {
  // Если categoryValue не передан, возвращаем "Неизвестная категория"
  if (categoryValue === undefined || categoryValue === null) {
    return "Неизвестная категория";
  }

  // Ищем категорию
  const category = titles.find((e) => e.value == categoryValue);

  if (!category) {
    return "Неизвестная категория";
  }

  // Если itemValue не передан, возвращаем название категории
  if (itemValue === undefined || itemValue === null) {
    return category.title;
  }

  // Ищем подкатегорию
  const item = category.items?.find((i) => i.value == itemValue);

  if (!item) {
    return "Неизвестная тема";
  }

  return item.title;
};

export { titles, isTitle };