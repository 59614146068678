import React from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "../pages/NotFound";

import Orders from "../pages/order";
import OrderEdit from "../pages/order/Edit";

import Notifications from "../pages/notification";
import NotificationCreate from "../pages/notification/Create";

import Categories from "../pages/category";
import CategoryCreate from "../pages/category/Create";
import CategoryEdit from "../pages/category/Edit";

import Products from "../pages/product";
import ProductCreate from "../pages/product/Create";
import ProductEdit from "../pages/product/Edit";

import Params from "../pages/param";
import ParamCreate from "../pages/param/Create";
import ParamEdit from "../pages/param/Edit";

import Users from "../pages/user";
import UserEdit from "../pages/user/Edit";

import Sales from "../pages/sale";
import SaleCreate from "../pages/sale/Create";
import SaleEdit from "../pages/sale/Edit";

import Documents from "../pages/document";
import DocumentCreate from "../pages/document/Create";
import DocumentEdit from "../pages/document/Edit";

import Members from "../pages/member";
import MemberEdit from "../pages/member/Edit";

import Account from "../pages/account";

import { useSelector } from "react-redux";
import Dialogs from "../pages/dialog";
import Payment from "../pages/invoice";
import Invoices from "../pages/invoice/Invoices";
import Invoice from "../pages/invoice/View";
import MemberCreate from "../pages/member/Create";
import Transactions from "../pages/transaction";
import Withdrawals from "../pages/withdrawal";
import StopWord from "../pages/stopWord";
import CreateStopWord from "../pages/stopWord/Create";
import EditStopWord from "../pages/stopWord/Edit";
import Tasks from "../pages/task";
import Reviews from "../pages/reviews";
import Reports from "../pages/reports";
// import ActionsLog from "../pages/member/ActionsLog";
import Histories from "../pages/history";
import EditTask from "../pages/task/Edit";
import MemberTasks from "../pages/member/Tasks";

import Cert from "../pages/cert";
import CertEdit from "../pages/cert/Edit";
import CertCreate from "../pages/cert/Create";

const AdminRouter = () => {
  const role = useSelector((state) => state.auth.user.role);

  return (
    <Routes>
      <Route path="/">
        <Route path="account" element={<Account />} />
        {role != 3 && <>
          <Route path="stopwords" element={<StopWord />} />
          <Route path="stopwords/create" element={<CreateStopWord />} />
          <Route path="stopword/:stopwordId" element={<EditStopWord />} />
          <Route path="histories" element={<Histories />} />
          {role != 4 && (
            <>
              <Route path="tasks" element={<Tasks />} />
              <Route path="task/:taskId" element={<EditTask />} />

              <Route path="dialogs" element={<Dialogs />} />
              <Route path="dialogs/:dialogId" element={<Dialogs />} />

              <Route path="payment" element={<Payment />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="invoice/:invoiceId" element={<Invoice />} />

              <Route path="notifications" element={<Notifications />} />
              <Route
                path="notifications/create"
                element={<NotificationCreate />}
              />

              <Route path="users" element={<Users />} />
              <Route path="user/:userId" element={<UserEdit />} />
              <Route path="members" element={<Members />} />
              {/* <Route path="members/log" element={<ActionsLog />} /> */}
              {role == 1 && (
                <>
                  <Route path="members/create" element={<MemberCreate />} />
                  <Route path="member/:memberId" element={<MemberEdit />} />
                  <Route path="member/:memberId/tasks" element={<MemberTasks />} />
                </>
              )}

              <Route path="reviews" element={<Reviews />} />

              <Route path="reports" element={<Reports />} />
            </>
          )}
          <Route path="products" element={<Products />} />
          <Route path="product/:productId" element={<ProductEdit />} />
          <Route path="products/create" element={<ProductCreate />} />

          <Route path="articles" element={<Documents />} />
          <Route path="article/:documentId" element={<DocumentEdit />} />
          <Route path="articles/create" element={<DocumentCreate />} />

          <Route path="documents" element={<Cert />} />
          <Route path="document/:documentId" element={<CertEdit />} />
          <Route path="document/create" element={<CertCreate />} />

          <Route path="sales" element={<Sales />} />
          <Route path="sale/:saleId" element={<SaleEdit />} />
          <Route path="sales/create" element={<SaleCreate />} />

          <Route path="games" element={<Categories />} />
          <Route path="game/:categoryId" element={<CategoryEdit />} />
          <Route path="games/create" element={<CategoryCreate />} />

          <Route path="params" element={<Params />} />
          <Route path="param/:paramId" element={<ParamEdit />} />
          <Route path="params/create" element={<ParamCreate />} />
        </>
        }

        {role != 4 && (
          <>
            <Route path="deals" element={<Orders />} />
            <Route path="deal/:orderId" element={<OrderEdit />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transaction/:transactionId" element={<OrderEdit />} />
            <Route path="withdrawals" element={<Withdrawals />} />
            <Route path="withdrawal/:withdrawalId" element={<OrderEdit />} />
          </>
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
