import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import CustomEditor from "../../components/editor/CustomEditor";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import { createCert } from "../../services/cert";
import TableEditor from "../../components/editor/TableEditor";
import Select from "../../components/UI/Select";

const СertCreate = () => {
  const navigate = useNavigate();
  const [editImageSale, setEditImageSale] = useState({
    show: false,
    data: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const handleAddTable = () => {
    const currentData = getValues("data") || []; // Защита от undefined
    const newTable = {
      name: `Таблица ${currentData.length + 1}`,
      content: []
    };
    setValue("data", [...currentData, newTable]);
  };

  const onSubmit = useCallback(() => {
    createCert(data)
      .then(() => {
        NotificationManager.success("Документ успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, [data]);

  return (
    <Container fluid={true}>
      <Meta title="Создать документ" />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to="/documents"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
          <button
            className="btn-primary"
            disabled={!isValid}
            onClick={() => onSubmit()}
          >
            Сохранить изменения
          </button>
        </div>
        <h3 className="mb-4">Создать документ</h3>
        <Select
          className="mb-3"
          label="Категория"
          onClick={(e) => setValue("status", e.value)}
          value={data.status ?? ""}
          data={[
            { title: "Не указано", value: "" },
            { title: "Информация", value: 1 },
            { title: "Правовая информация", value: 2 },
            { title: "Архив", value: 3 },
          ]}
        />
        <div className="mb-3">
          <Input
            label="Название"
            name="title"
            placeholder="Заголовок"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <div className="mb-3">
          <Input
            label="URL адрес"
            name="link"
            placeholder="url адрес"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <div className="mb-3">
          <Input
            label="Порядок"
            name="priority"
            placeholder="0"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <CustomEditor
          content={data.content || ''}
          onChange={(e) => setValue("content", e)}
          editorId={"content"}
          height={500}
        />
        <h3 className="mt-3">Дополнительные поля для правила сайта</h3>

        <div className="mt-3 mb-3">
          <button
            type="button"
            className="btn-primary"
            onClick={handleAddTable}
          >
            Добавить таблицу
          </button>
        </div>

        {data?.data?.map((table, index) => (
          <div key={index} className="mb-4">
            <div className="mb-2">
              <Input
                label="Название таблицы"
                value={table.name}
                onChange={(e) => setValue(`data[${index}].name`, e.value)}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
            <TableEditor
              tableData={table.content}
              onChange={(e) => setValue(`data[${index}].content`, e)}
            />
          </div>
        ))}
        {/* <Col md={12} className="doc mt-3">
            <CustomEditor
              content={data.desc}
              onChange={(e) => setValue("desc", e)}
            />
          </Col> */}
      </section>
    </Container >
  );
};

export default СertCreate;
