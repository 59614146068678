import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { IoNotificationsOutline, IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import useIsMobile from "../hooks/isMobile";
// import MobileNav from "./MobileNav";

import { logout } from "../services/auth";

import Button from "./UI/Button";
import CustomModal from "./utils/CustomModal";

const Date = () => {
  const [date, setDate] = useState(moment().format("DD MMM YYYY kk:mm:ss"));

  const updateTime = () => setDate(moment().format("DD MMM YYYY kk:mm:ss"));

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return date;
};

const Header = () => {
  // const isMobile = useIsMobile();
  const state = useSelector((state) => state);
  const user = useSelector((state) => state.auth.user);
  const [isShowBurgerMenu, setShowBurgerMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeBurgerMenu = useCallback(() => setShowBurgerMenu(false), []);
  const [showLogout, setShowLogout] = useState(false);

  return (
    <>
      <header>
        <Container fluid className="h-100">
          <Navbar collapseOnSelect expand="lg">
            <Container className="p-0">
              <Navbar.Toggle aria-controls="navbar-dark-example" />
              <Navbar.Collapse id="navbar-dark-example">
                <Nav className="me-auto my-2 my-lg-0">
                  {/* <Nav.Link>
                    <IoSearchOutline size={24} />
                  </Nav.Link> */}
                </Nav>
                <Nav className="d-flex">
                  <Nav.Link className="nav-date">
                    <Date />
                  </Nav.Link>
                  <Dropdown className="d-flex align-items-center">
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          className="py-0"
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <img
                            src="/images/lang/ru.svg"
                            height={20}
                            className="language"
                          />
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/ru.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        Русский
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/en.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        English
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/de.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        Germany
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/account"
                        className="d-flex align-items-center flex-row fs-09"
                      >
                        <img
                          src="/images/lang/fr.svg"
                          height={17}
                          width={24}
                          className="me-2"
                        />
                        France
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Dropdown>
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <IoNotificationsOutline size={24} />
                          {state?.auth?.user?.notificationCount > 0 && (
                            <Badge pill className="ms-2" bg="danger">
                              {state.auth.user.notificationCount}
                            </Badge>
                          )}
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <div className="notification-menu">
                        <p className="fw-7 fs-09">Уведомления</p>
                        <p className="text-muted fs-09">
                          У вас нет новых уведомлений
                        </p>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <NavLink to={`/member/${user.id}/tasks`} className="headerTask">
                    <span className="d-flex flex-row align-items-center">
                      <svg
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="icons/navbar/ic_kanban">
                          <path
                            id="shape"
                            opacity="0.32"
                            d="M22 10.5C22 13.2058 21.9268 15.3259 21.8596 16.7018C21.8139 17.6385 20.9066 18.311 19.9771 18.1857C18.4531 17.9803 15.8791 17.75 12 17.75C8.1209 17.75 5.54695 17.9803 4.02285 18.1857C3.09341 18.311 2.18614 17.6385 2.14039 16.7018C2.07321 15.3259 2 13.2058 2 10.5C2 7.79427 2.07321 5.67417 2.14039 4.29829C2.18614 3.36156 3.09341 2.68909 4.02285 2.81433C5.54695 3.0197 8.1209 3.25002 12 3.25002C15.8791 3.25002 18.4531 3.0197 19.9771 2.81433C20.9066 2.68909 21.8139 3.36156 21.8596 4.29829C21.9268 5.67417 22 7.79427 22 10.5Z"
                          />
                          <g id="shape_2">
                            <path d="M5.59096 18.0096C5.28385 18.7423 4.94579 19.6105 4.65668 20.5C4.5685 20.7713 4.73269 21.0577 5.01662 21.0852C5.48092 21.1304 5.97612 21.1092 6.35562 21.0766C6.76872 21.0412 7.13172 20.805 7.35657 20.4566C7.94702 19.542 8.51881 18.5894 8.97868 17.8008C7.63073 17.8483 6.50815 17.9246 5.59096 18.0096Z" />
                            <path d="M15.022 17.8008C15.4818 18.5894 16.0536 19.542 16.6441 20.4566C16.8689 20.805 17.2319 21.0412 17.645 21.0766C18.0245 21.1092 18.5197 21.1304 18.984 21.0852C19.268 21.0577 19.4321 20.7713 19.344 20.5C19.0549 19.6105 18.7168 18.7424 18.4097 18.0097C17.4925 17.9246 16.37 17.8483 15.022 17.8008Z" />
                          </g>
                        </g>
                      </svg>
                    </span>
                    {/* {notification?.task > 0 && (
                      <Badge className="ms-2" bg="danger">
                        {notification.task}
                      </Badge>
                    )} */}
                  </NavLink>
                  <Dropdown className="d-flex align-items-center">
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <Nav.Link
                          ref={ref}
                          className="py-0"
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <img src="/images/avatar-full.png" height={35} />
                        </Nav.Link>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <div className="account-menu">
                        <p className="fw-7 fs-09">
                          {state.auth.user.firstName} {state.auth.user.lastName}
                        </p>
                        <p className="text-muted fs-09">
                          ID {state.auth.user.id}
                        </p>
                      </div>
                      <Dropdown.Divider className="dashed" />
                      <Dropdown.Item as={Link} to="/account">
                        Профиль
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        as={Link}
                        to={"/member/" + state.auth.user.id}
                      >
                        Мои задачи
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item as={Link} to="/payment">
                        Оплата
                      </Dropdown.Item> */}
                      <Dropdown.Divider className="dashed" />
                      <Dropdown.Item onClick={() => setShowLogout(!showLogout)}>
                        Выход
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </header>
      {/* 
      <MobileNav /> */}

      <Offcanvas show={isShowBurgerMenu} onHide={closeBurgerMenu}>
        <Offcanvas.Body>
          <Container className="h-100 d-flex flex-column justify-content-between">
            <div>
              <nav className="mobile-menu-left">
                <ul className="list-unstyled">
                  {!state.auth.isAuth && (
                    <li>
                      <Button
                        type="button"
                        onClick={() => {
                          navigate("/");
                          closeBurgerMenu();
                        }}
                      >
                        Войти
                      </Button>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className="mt-4 mt-sm-5">
              <div className="light-gray fs-09">
                <a href="/">Политика обработки персональных данных</a>
              </div>
            </div>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <CustomModal
        show={showLogout}
        setShow={setShowLogout}
        title="Подтвердите действие"
        footer={
          <>
            <Button
              type="button"
              className="btn-default"
              onClick={() => setShowLogout(!showLogout)}
            >
              Отмена
            </Button>
            <Button
              type="button"
              className="btn-primary"
              onClick={() => {
                dispatch(logout());
                navigate("/login");
              }}
            >
              Выйти
            </Button>
          </>
        }
      >
        Вы точно хотите выйти?
      </CustomModal>
    </>
  );
};

export default Header;
