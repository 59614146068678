import moment from "moment";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge, Card, Col, Row, Container, Form } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChatboxOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { roles } from "../../helpers/member";
import { editMember, getMember } from "../../services/member";
import { getTasks } from "../../services/task";
import { titles } from "../../helpers/titles";

const MemberTasks = () => {
  const { memberId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tasks, setTasks] = useState({
    loading: true,
    items: [],
  });
  useLayoutEffect(() => {
    getMember({ id: memberId, taskNeed: true })
      .then((res) => {
        setTasks((prev) => ({
          ...prev,
          loading: false,
          member: res?.member,
          ...res?.tasks,
        }));
      })
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);


  const taskColumns = [
    {
      width: "130px",
      name: "Тип",
      align: "center",
      cell: (row) =>
        row.type === "task" ? (
          <Badge bg="success">Обращение</Badge>
        ) : row.type === "word" ? (
          <Badge bg="warning">Стоп-слово</Badge>
        ) : (
          row.type === "report" && <Badge bg="danger">Жалоба</Badge>
        ),
    },
    {
      name: "Дата",
      selector: "createdAt",
      cell: (row) => (
        <>
          <span className="me-1">
            {moment(row.createdAt).format("DD.MM.YYYY")}
          </span>
          <span className="fw-7">{moment(row.createdAt).format("kk:mm")}</span>
        </>
      ),
    },

    {
      width: "130px",
      name: "Статус",
      align: "center",
      cell: (row) =>
        !row.status || row.status === "new" ? (
          <Badge bg="success">Новый</Badge>
        ) : row.status === "process" ? (
          <Badge bg="secondary">В процессе</Badge>
        ) : row.status === "ok" ? (
          <Badge bg="success">Выполнено</Badge>
        ) : (
          row.status === "close" && <Badge bg="danger">Закрыто</Badge>
        ),
    },
    {
      name: "Пользователь",
      cell: (row) => (
        <Link to={`/user/${row?.user?.id}`}>{row?.user?.nickname}</Link>
      ),
    },
    {
      align: "center",
      name: "Тема",
      cell: (row) => (row?.title ? row.title : "-"),
    },
    {
      align: "center",
      name: <IoChatboxOutline size={18} />,
      cell: (row) => (row?.comment ? row.comment : "-"),
    },
    {
      align: "right",
      name: "",
      cell: (row) => {
        return <Link to={"/task/" + row.id} className="btn-primary ms-2 me-2 btn-sm">Перейти</Link>;
      },
    },

    // {
    //   width: "80px",
    //   selector: "action",
    //   align: "right",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="d-flex align-items-center">
    //           <Link to={"/task/" + row.id} className="me-3">
    //             <IoCreateOutline size={22} />
    //           </Link>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-4">
        {memberId == user.id ?
          <h3 className="fw-7">Мои Задачи</h3>
          :
          <h3 className="fw-7">Задачи сотрудника {tasks?.member?.lastName} {tasks?.member?.firstName} {tasks?.member?.patronymic}</h3>
        }
      </div>
    );
  }, [tasks]);
  if (loading) {
    return <Loader full />;
  }

  if (!tasks?.member?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Нет информации о сотруднике</h3>
      </Info>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Задачи сотрудника" />
      <section className="box">
        <DataTable
          columns={taskColumns}
          onChange={(items) => setSelected(items)}
          data={tasks.items}
          header={header}
          selectable
          pagination={tasks.pagination}
        />
      </section>
    </Container>
  );
};

export default MemberTasks;
